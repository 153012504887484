import gql from 'graphql-tag';

export const RENTER_SAVED_CARDS = gql`
  query RenterSavedCards {
    renterSavedCards {
      savedCreditCards {
        id
        last4
        brand
        customer
        funding
        exp_month
        exp_year
        stripeAccount
      }
      error
    }
  }
`;
