import React, { useState, useEffect, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { CircularProgress } from '@material-ui/core';
import { RENTER_SAVED_CARDS } from 'Queries/Renter/RenterSavedCards';
import styled from 'styled-components';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { DELETE_RENTER_CARD } from 'Mutations/deleteRenterCard';
import { SnackbarContext } from 'Store/SnackbarContext';

function RenterSavedCards() {
  const [cards, setCards] = useState([]);
  const [deletingId, setDeletingId] = useState(null);
  const { showSnackbar } = useContext(SnackbarContext);
  const { loading, data, error } = useQuery(RENTER_SAVED_CARDS, {
    fetchPolicy: 'network-only'
  });
  const [deleteRenterCardMut] = useMutation(DELETE_RENTER_CARD);

  useEffect(() => {
    if (!error && !loading && data?.renterSavedCards?.savedCreditCards) {
      setCards(data.renterSavedCards.savedCreditCards);
    }
  }, [loading, data, error]);

  const handleClick = async card => {
    if (!deletingId) {
      setDeletingId(card.id);
      const result = await deleteRenterCardMut({
        variables: {
          input: {
            cardId: card.id,
            customer: card.customer,
            stripeAccount: card.stripeAccount
          }
        }
      });

      if (result?.data?.deleteRenterCard?.success) {
        const newCards = cards.filter(c => c.id !== card.id);
        setCards(newCards);
        showSnackbar('The card was deleted successfully.', {
          duration: 5000,
          error: false
        });
      } else {
        showSnackbar('The card could not be deleted. Try again later.', {
          duration: 5000,
          error: true
        });
      }

      setDeletingId(null);
    }
  };

  if (loading) {
    return (
      <RenterSavedCardsDivLoadOrErr>
        <CircularProgress />
      </RenterSavedCardsDivLoadOrErr>
    );
  }

  if (error)
    return (
      <RenterSavedCardsDivLoadOrErr>
        <div>There was an error. Try again later, please.</div>;
      </RenterSavedCardsDivLoadOrErr>
    );
  return (
    <RenterSavedCardsDiv isMobile={isMobile}>
      <h2>SAVED CARDS</h2>
      <div>
        <ul>
          {cards.map(card => {
            const expirationDate = moment(`${card.exp_month}-${card.exp_year}`, 'MM-YYYY');
            const now = moment();
            const isExpired = now.isAfter(expirationDate, 'month');
            return (
              <li key={card.id}>
                <div className={`card-content ${isExpired ? ' expired' : ''}`}>
                  {isExpired && <span className="expired-label">EXPIRED</span>}
                  <span>
                    <strong>{card.brand?.toUpperCase()}</strong>
                  </span>
                  <span>**** **** **** {card.last4}</span>
                  <span>({card.funding ? card.funding : 'credit'})</span>
                  <span>
                    {card.exp_month} / {card.exp_year}
                  </span>
                  <span className="button-wrapper">
                    <button type="button" onClick={() => handleClick(card)}>
                      DELETE
                    </button>
                    {card.id === deletingId && (
                      <span>
                        <CircularProgress />
                      </span>
                    )}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
        {cards.length === 0 && <div>No saved cards.</div>}
      </div>
    </RenterSavedCardsDiv>
  );
}

const RenterSavedCardsDivLoadOrErr = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RenterSavedCardsDiv = styled.div`
  margin-top: 100px;

  h2 {
    font-weight: 600;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    max-width: 70%;
  }

  li .card-content {
    height: 50px;
    border: 1px solid;
    padding: 5px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 15px;
  }

  li .card-content.expired {
   border-color: red;
  }

  .expired-label {
    color: red;
    font-size: 10px;
  }

  button {
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    font-size: 17px;
    font-weight: 600;
  }

  button:hover {
    color: red;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${({ isMobile }) =>
    isMobile &&
    `
      ul {
        max-width: 95%;
      }
    `}
`;

export default RenterSavedCards;
