import gql from 'graphql-tag';

export const DELETE_RENTER_CARD = gql`
  mutation deleteRenterCard($input: DeleteRenterCardInput) {
    deleteRenterCard(input: $input) {
      cardId
      success
      error
    }
  }
`;
