/**
 * Log error and display user feedback snackbar on failure
 */
const reportGraphqlError = (showSnackbar, message, error = null, role) => {
  const duration = 10000;
  const action = 'Close';

  // eslint-disable-next-line
  if (error) console.error(`${message}: ${error}`);
  if (showSnackbar && typeof showSnackbar === 'function')
    showSnackbar(message, {
      error: true,
      duration,
      action
    });
};

export { reportGraphqlError };
